.banner {
  padding: 25px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
  width: 86%;
  position: fixed;
  bottom: 10px;
  /* height: 100px; */
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
}

.cookie-banner {
  position: absolute !important;
  bottom: 0 !important;
  max-width: 95% !important;
  left: 0 !important;
  right: 0 !important;
  margin: 10px auto !important;
  /* transform: none !important; */
}

.banner-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.banner-heading {
  font-weight: bold;
  font-size: 20px;
}

.banner-text {
  margin-top: 10px;
}

.banner-btn1 {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  margin-right: 15px;
}

.banner-btn1:hover {
  background-color: var(--primary-hover-color);
}

.banner-btn2 {
  border: 1px solid var(--primary-color);
  padding: 10px 20px;
  border-radius: 10px;
  background: transparent;
  color: var(--primary-color);
}

.banner-btn2:hover {
  color: white;
  background-color: var(--primary-color);
}

.banner a {
  color: black;
  font-weight: 500;
}

.banner-later-btn {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  .banner-btn1,
  .banner-btn2 {
    width: 100%;
  }

  .banner-btn1 {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
